<template>
  <v-select
    v-model="selected"
    :items="items"
    :label="label"
    :clearable="clearable"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    label: {
      required: false,
      type: String,
      default: "Sales status"
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [
      {
        text: "All",
        value: ""
      },
      {
        text: "New",
        value: "new"
      },
      {
        text: "Transfered to payout account",
        value: "paid_out"
      },
      {
        text: "Rejected",
        value: "rejected"
      }
    ]
  }),
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
