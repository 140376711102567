<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      :items-per-page="50"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              <sales-status-icon :sale="item"></sales-status-icon>
            </td>
            <td class="word-no-wrap">
              {{ item.created_at | utc_to_local_datetime }}
            </td>
            <td>
              <template v-if="item.click">
                {{ timeDifference(item.click.created_at, item.created_at) }}
              </template>
            </td>
            <td>
              {{ item.business ? item.business.display_name : "-" }}
            </td>
            <td class="text-right">
              <span>DKK {{ item.commission | local_numbers }}</span>
              <span v-if="item.original_currency !== 'DKK'"> ({{ item.original_currency }} {{ item.commission * item.exchange_rate | local_numbers }})</span>
            </td>
            <td class="text-right">
              <v-btn outlined icon color="primary" @click="selectedSale = item">
                <v-icon small>fal fa-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>

      <div class="text-center" slot="no-data">
        We didn't find any sales.
        <router-link :to="{ name: 'campaigns' }">Find new campaigns</router-link
        >.
      </div>
    </v-data-table>
    <v-pagination
      v-model="currPage"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
    <sales-information-dialog v-model="selectedSale"></sales-information-dialog>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import payout_status from "@/helpers/filters/payout_status";
import SalesStatusIcon from "../../icons/SalesStatusIcon";
import time_difference from "@/helpers/mixins/time_difference";
import SalesInformationDialog from "../dialogs/SalesInformationDialog";

export default {
  components: {
    SalesInformationDialog,
    SalesStatusIcon
  },
  filters: {
    local_numbers,
    utc_to_local_datetime,
    payout_status
  },
  mixins: [time_difference],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    page: {
      type: Number
    },
    total: {
      type: Number
    }
  },
  data: () => ({
    selectedSale: null,
    currPage: 1,
    headers: [
      {
        text: "Status",
        sortable: false
      },
      {
        text: "Date",
        sortable: false
      },
      {
        text: "Conversion time",
        sortable: false
      },
      {
        text: "Brand",
        sortable: false
      },
      {
        text: "Commission",
        sortable: false,
        align: "right"
      },
      {
        text: "",
        sortable: false
      }
    ]
  }),
  watch: {
    items() {
      this.currPage = this.page;
    }
  },
  methods: {
    handlePageChange(value) {
      this.$emit("changePage", value);
    }
  }
};
</script>
