import moment from "moment";

export default {
  methods: {
    // Calculate time between two dates
    timeDifference(startDate, endDate) {
      if (!startDate || !endDate) {
        return "";
      }

      let start = moment(startDate, "YYYY-MM-DD HH:mm:ss");
      let end = moment(endDate, "YYYY-MM-DD HH:mm:ss");

      let duration = moment.duration(end.diff(start));

      if (duration.asHours() >= 24) {
        return Math.round(duration.asDays()) + " days";
      } else if (duration.asMinutes() >= 120) {
        return Math.round(duration.asHours()) + " hours";
      } else if (duration.asSeconds() < 120) {
        return duration.asSeconds() + " seconds";
      }

      return Math.round(duration.asMinutes()) + " minutes";
    }
  }
};
