var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user.business)?[(_vm.sale.approved_at === null && _vm.sale.disapproved_at === null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-hourglass-start")])]}}],null,false,3164078568)},[_c('span',[_vm._v("The sale is new and not yet approved for invoicing.")])]):(_vm.sale.disapproved_at !== null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-times")])]}}],null,false,2360201513)},[_c('span',[_vm._v("The sale has been rejected. I.e., a duplicate order, fraud or similar and will not be paid out.")])]):(_vm.sale.paid_at !== null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fa-money-bill-wave")])]}}],null,false,846423098)},[_c('span',[_vm._v("The sale has been invoiced")])]):(_vm.sale.approved_at !== null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fal fa-check")])]}}],null,false,3716712034)},[_c('span',[_vm._v("The sale is approved, but not yet invoiced.")])]):_vm._e()]:[(_vm.sale.approved_at === null && _vm.sale.disapproved_at === null)?_c('v-chip',{attrs:{"color":"secondary","label":"","text-color":"primary","x-small":""}},[_c('v-icon',{attrs:{"left":"","color":"primary","x-small":""}},[_vm._v(" fal fa-certificate ")]),_vm._v(" New ")],1):(_vm.sale.disapproved_at !== null)?_c('v-chip',{attrs:{"color":"secondary","label":"","text-color":"primary","x-small":""}},[_c('v-icon',{attrs:{"left":"","color":"primary","x-small":""}},[_vm._v(" fal fa-times ")]),_vm._v(" Rejected ")],1):(_vm.sale.payout_at === null)?_c('v-chip',{attrs:{"color":"secondary","label":"","text-color":"primary","x-small":""}},[_c('v-icon',{attrs:{"left":"","color":"primary","x-small":""}},[_vm._v(" fal fa-hourglass-start ")]),_vm._v(" Being processed ")],1):(_vm.sale.payout_at !== null)?_c('v-chip',{attrs:{"color":"secondary","label":"","text-color":"primary","x-small":""}},[_c('v-icon',{attrs:{"left":"","color":"primary","x-small":""}},[_vm._v(" fal fa-money-bill-wave ")]),_vm._v(" Transferred ")],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }