<template>
  <div>
    <template v-if="user.business">
      <v-tooltip
        top
        v-if="sale.approved_at === null && sale.disapproved_at === null"
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">fal fa-hourglass-start</v-icon>
        </template>
        <span>The sale is new and not yet approved for invoicing.</span>
      </v-tooltip>
      <v-tooltip top v-else-if="sale.disapproved_at !== null">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">fal fa-times</v-icon>
        </template>
        <span
          >The sale has been rejected. I.e., a duplicate order, fraud or similar
          and will not be paid out.</span
        >
      </v-tooltip>
      <v-tooltip top v-else-if="sale.paid_at !== null">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">fa-money-bill-wave</v-icon>
        </template>

        <span>The sale has been invoiced</span>
      </v-tooltip>
      <v-tooltip top v-else-if="sale.approved_at !== null">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">fal fal fa-check</v-icon>
        </template>
        <span>The sale is approved, but not yet invoiced.</span>
      </v-tooltip>
    </template>
    <template v-else>
      <v-chip
       v-if="sale.approved_at === null && sale.disapproved_at === null"
       color="secondary"
       label
       text-color="primary"
       x-small
      >
        <v-icon left color="primary" x-small>
          fal fa-certificate
        </v-icon>
        New
      </v-chip>
      <v-chip
         v-else-if="sale.disapproved_at !== null"
         color="secondary"
         label
         text-color="primary"
         x-small
      >
        <v-icon left color="primary" x-small>
          fal fa-times
        </v-icon>
        Rejected
      </v-chip>
      <v-chip
         v-else-if="sale.payout_at === null"
         color="secondary"
         label
         text-color="primary"
         x-small
      >
        <v-icon left color="primary" x-small>
          fal fa-hourglass-start
        </v-icon>
        Being processed
      </v-chip>
      <v-chip
         v-else-if="sale.payout_at !== null"
         color="secondary"
         label
         text-color="primary"
         x-small
      >
        <v-icon left color="primary" x-small>
          fal fa-money-bill-wave
        </v-icon>
        Transferred
      </v-chip>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    sale: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState("core/auth", ["user"])
  }
};
</script>

<style scoped></style>
