<template>
  <v-dialog v-if="value !== null" v-model="showing" max-width="550px">
    <v-card>
      <v-card-title class="headline" primary-title>
        Sales information
      </v-card-title>

      <v-card-text>
        <v-list two-line>
          <v-list-item>
            <v-list-item-action>
              <v-icon>fal fa-user</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <template v-if="value.click">
                  {{ value.click.campaign.business.display_name }}
                </template>
                <template v-else-if="value.business">
                  {{ value.business.display_name }}
                </template>
              </v-list-item-title>
              <v-list-item-subtitle>Company</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.click">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                <router-link
                  :to="{
                    name: 'campaign',
                    params: { id: value.click.campaign.id }
                  }"
                >
                  {{ value.click.campaign.name }}
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle>Campaign</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item v-if="value.click">
            <v-list-item-action>
              <v-icon>fal fa-calendar-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                timeDifference(value.click.created_at, value.created_at)
              }}</v-list-item-title>
              <v-list-item-subtitle>Time of conversion </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.created_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Time of sale</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.click">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.click.created_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Time of click </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-if="value.promotion_code">
            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-action></v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{
                  value.promotion_code.code
                }}</v-list-item-title>
                <v-list-item-subtitle>Promotion code</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider inset></v-divider>

          <v-list-item v-if="value.click">
            <v-list-item-action>
              <v-icon>fal fa-link</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <a
                  v-if="value.click.http_link"
                  :href="value.click.http_link.link"
                  target="_blank"
                  >{{ value.click.http_link.link }}</a
                >
                <v-icon v-else small color="error">fal fa-times</v-icon>
              </v-list-item-title>
              <v-list-item-subtitle>Deep link</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>fal fa-analytics</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <sales-status-icon :sale="value"></sales-status-icon>
              </v-list-item-title>
              <v-list-item-subtitle>Sales status</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.disapproved_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.disapproved_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Time of rejection</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.disapproved_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.disapproved_note
              }}</v-list-item-title>
              <v-list-item-subtitle>Rejection note</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.approved_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.approved_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle
                >Time of approval</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.paid_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.paid_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Time of payment</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.payout_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.payout_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Time of payout</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import SalesStatusIcon from "../../icons/SalesStatusIcon";
import time_difference from "@/helpers/mixins/time_difference";

export default {
  components: { SalesStatusIcon },
  mixins: [time_difference],
  filters: {
    utc_to_local_datetime
  },
  props: {
    value: {
      required: true
    }
  },
  computed: {
    showing: {
      get() {
        return this.value !== null;
      },
      set() {
        this.$emit("input", null);
      }
    }
  }
};
</script>
