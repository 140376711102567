<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Sales report</div>
          <span class="subtitle-1 light-grey"
            >Overview of each sales you've generated</span
          >
        </div>
        <v-spacer></v-spacer>

        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges">
              <template v-if="filtersCount > 0" v-slot:badge>{{
                filtersCount
              }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                  filtersTmp = cloneDeep(filters);
                  showFiltersDialog = true;
                "
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>

        <!--Mobile button-->
        <v-btn
          color="primary"
          class="hidden-sm-and-up"
          fixed
          bottom
          right
          fab
          @click="
            filtersTmp = cloneDeep(filters);
            filterDialog = true;
          "
        >
          <v-icon>fas fa-filter</v-icon>
        </v-btn>
         
      </v-card-title>
    
      <v-card-text>
         <div class="text-right total-shown">
            {{ limit }} out of {{ totalAmount }}
        </div>
        <sales-data-table
          class="mt-4"
          :loading="loading"
          :items="items"
          :page="page"
          :total="total"
          @changePage="getStats($event)"
        ></sales-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showFiltersDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter report
        </v-card-title>
        <v-card-text>
          <sales-status-select
            v-model="filtersTmp.status"
          ></sales-status-select>
          <business-select
            v-model="filtersTmp.business_uuid"
            :return-object="false"
          ></business-select>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Start date"
            cancel-button
            reset-button
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="End date"
            cancel-button
            reset-button
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = cloneDeep(filtersTmp);
              showFiltersDialog = false;
            "
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SalesDataTable from "../../data-tables/SalesDataTable";
import DatePicker from "@/components/common/filters/DatePicker";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import SalesStatusSelect from "../../../selects/SalesStatusSelect";
import BusinessSelect from "../../../selects/BusinessSelect";
import { mapActions } from "vuex";

export default {
  components: {
    SalesStatusSelect,
    DatePicker,
    SalesDataTable,
    BusinessSelect
  },
  mixins: [search_filters_url],
  data: () => ({
    loading: true,
    items: [],
    showFiltersDialog: false,
    filtersTmp: {},
    filters: {},
    page: 1,
    total: null,
    limit: 50,
    totalAmount: null
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.getStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", ["getSales"]),

    getStats(page = 1) {
      this.page = page;
      this.loading = true;
      this.items = [];
      const filters = this.cloneDeep(this.filters);
      const params = {
        ...filters,
        limit: this.limit,
        page: this.page
      };
      this.getSales(params).then(sales => {
        this.totalAmount = sales.total;
        this.total = sales.lastPage;
        this.items = sales.response;
        this.loading = false;
      });
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();

    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment()
        .endOf("month")
        .format("YYYY-MM-DD");
      this.filters.status = "";
    } else {
      this.getStats();
    }
  }
};
</script>
