export default function(status) {
  switch (status) {
    case "paid":
      return "Betalt";

    case "pending":
      return "Afventer";

    case "in_transit":
      return "I gang";

    case "canceled":
      return "Annulleret";
  }

  return status;
}
